



































































































import SKHero from '@/components/SK-Hero.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    SKHero
  }
})
export default class Impressum extends Vue {}
